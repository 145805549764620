<template>
  <div>
    <div class="bg_wra">
      <h1>寓信新闻动态</h1>
      <h4>助力企业数字化转型升级，帮助企业降本增效</h4>
    </div>
    <div class="news_con">
      <a-tabs :default-active-key="type" @change="callback">
        <a-tab-pane key="1" tab="新闻动态" force-render></a-tab-pane>
        <a-tab-pane key="2" tab="行业观察" force-render></a-tab-pane>
        <a-tab-pane key="3" tab="党建动态" force-render></a-tab-pane>
      </a-tabs>
      <a-row :gutter="[25, 25]">
        <a-col :span="14" :md="14" :sm="24" :xs="24">
          <ul>
            <li v-for="item in list" :key="item.id" @click="seeDetails(item)">
              <a-row type="flex" class="news-item">
                <a-col class="order">
                  <img :src="item.linkAddress" alt="" />
                </a-col>
                <a-col>
                  <div class="news_text">
                    <div class="title">{{ item.title }}</div>
                    <div class="desc">{{ item.subtitle }}</div>
                    <div class="time">{{ item.releaseTime }}</div>
                  </div>
                </a-col>
              </a-row>
            </li>
          </ul>
          <a-pagination
            v-model="pageNo"
            :total="total"
            show-less-items
            class="pgn"
            @change="onChange"
          />
        </a-col>
        <a-col :span="10" :md="10" :sm="0" :xs="0">
          <div class="news-banner-warp">
            <img
              src="../assets/image/news-banner.png"
              class="news-banner"
              alt=""
            />
            <div class="nes-banner-content">
              <img src="../assets/image/QRCode1.jpeg" class="code" alt="" />
              <h4>关注寓信公众号</h4>
              <p>了解更多行业新资讯</p>
              <img
                src="../assets/image/news-banner-bg.png"
                class="conten-bg"
                alt=""
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { getNews } from "../api";

export default {
  name: "NewsCenter",
  data() {
    return {
      type: 1,
      list: [],
      total: 0,
      pageSize: 10,
      pageNo: 1,
    };
  },
  methods: {
    async getList(type) {
      const params = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        status: 1,
        type: type,
      };
      const res = await getNews(params);
      if (res.data.status.code == 200) {
        this.list = res.data.result;
        this.total = res.data.totalRecord;
      }
    },
    callback(key) {
      this.type = key;
      this.pageNo = 1;
      this.getList(this.type);
    },
    seeDetails(item) {
      //linkType=2，是外链地址
      if (item.linkType == 2) {
        window.open(item.content);
      } else {
        this.$router.push({ name: "details", params: { id: item.id } });
      }
    },
    onChange(cur) {
      this.pageNo = cur;
      this.getList(this.type);
    },
  },
  created() {
    this.getList(this.type);
  },
};
</script>

<style lang="less" scoped>
.bg_wra {
  height: 480px;
  // line-height: 480px;
  padding: 146px 0 0 120px;
  // line-height: 480px;
  // text-align: center;
  font-size: 40px;
  color: #fff;
  background: url("../assets/image/bg_news.png") no-repeat center;
  background-size: cover;
  h1 {
    font-weight: 700;
    font-size: 58px;
    color: #fff;
  }
  h4 {
    color: #fff;
    font-weight: 700;
    font-size: 28px;
  }
}
.news_con {
  width: 1200px;
  margin: 0 auto;
  ul {
    padding: 0;
    li {
      list-style: none;
      margin: 30px 0;
      cursor: pointer;
      img {
        width: 250px;
        height: 140px;
        margin-right: 30px;
      }
      .title {
        font-size: 18px;
        color: #333;
      }
      .desc {
        color: #999;
        margin: 10px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .time {
        color: #999;
      }
      &:hover .title {
        color: #0167ff;
      }
    }
  }
  .pgn {
    margin-bottom: 30px;
  }
}
.news-banner-warp {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 346px;
  margin: 30px 0;
  .news-banner {
    width: 346px;
    height: 194px;
  }

  .nes-banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #eff3ff;
    position: relative;
    .code {
      height: 126px;
      width: 126px;
    }
    h4 {
      color: #030d1e;
      font-size: 20px;
    }
    p {
      font-size: 14px;
      color: #607499;
    }
    .conten-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 145px;
    }
  }
}
@media screen and (max-width: 768px) {
  .bg_wra {
    height: 210px;
    padding: 94px 0 0 20px;
    font-size: 40px;
    color: #fff;
    background: url("../assets/image/new-bg-moblie.png") no-repeat center;
    background-size: cover;
    h1 {
      font-size: 26px;
      margin-bottom: 0;
    }
    h4 {
      color: #fff;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .news-banner-warp {
    display: none;
  }

  .order {
    order: 1;
  }
  .news_con {
    width: 100vw;
    ul {
      li {
        // padding-right: 30px;
        // margin: 11px 16px;
        margin: 0;
        .news-item {
          flex-wrap: nowrap;
          width: 100vw;
          justify-content: space-between;
          padding: 11px 16px;
        }
        img {
          // display: none;
          width: 106px;
          height: 80px;
          margin-right: 0;
          border-radius: 2px;
        }
        .news_text {
        }
        .title {
          color: #030d1e;

          font-weight: 500;
          font-size: 16px;
        }
        .desc {
          color: #607499;
          margin: 5px 0;
          // max-width: 50%;
          max-width: calc(100vw - 146px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .pgn {
      display: none;
    }
  }
}
</style>
